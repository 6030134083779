#printable-results{
    height: 1000px;
    width: 1500px;
    font-family: Mali-SemiBold !important;
    alignment: center;
    justify-content: center;
    padding: 5%;
}

#printable-results h1{
    width: 100%;
    font-size: 45px;
}

#results-results h4{
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
}

.result__graphics{
    margin-top: 60px;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.graphic{
    align-items: flex-end;
    justify-items: flex-end;
}

.secondary-graphics{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}

.results-logo{
    margin-left: 50px;
    min-height: 100%;
    width: 100%;
    align-items: flex-start;
}
