#take-assessment{
    min-height: calc(100vh - 120px);
}

.form-container{
    margin: 0;
    width: 100vw;
    height: 100%;
}

.form-container-row{
    margin-top: 50px;
    width: 100vw;
    min-height: calc(100vh - 120px);
}

.img-container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;

}

.img-container img{
    max-width: 100%;
    height: 50%;
    margin-bottom: 0 !important;
}

.form--btn{
    font-size: 25px;
}

.form-container-info{
    margin: 20% 40px auto 40px;
    padding: 30px;
    border-radius: 30px;
    background-color: rgba(69, 121, 178, 0.4);
    font-family: 'Raleway', sans-serif !important;
}

@media screen and (max-width: 900px){
    #take-assessment{
        height: auto;
    }
    .form-container{
        padding: 0;
        margin: 0;
    }
    .form-container-row{
        margin: 0;
    }

    .img-container{
        display: none;
    }
}