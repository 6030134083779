.results{
    width: 90%;
    font-family: Mali-SemiBold !important;
    alignment: center;
    justify-content: center;
    padding: 5%;
}

.results h4{
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
}

.results__container{
    width: 100%;
}

.results__title{
    width: 100%;
    alignment: center;
}

.results__name{
    margin-bottom: 30px;
}


.results__graphics{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.secondary-graphics{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.printable-results-invisible{
    display: none;
}

.printable-results-visible{
    transform: scale(0.7);
}

.static-printable{
    width: 1500px;
}

.results--btn{
    margin: 10px;
}


.progress-bar{
    height: 10px;
    width: 200px;
    background-color: rgba(0,0,0,0.3);
}

.progress-bar-striped{
    background-color: #D83D45;
}

.competence-container{
    padding: 10px;
}

.competence-container__title{}

.competence-container__level{
    margin: 0
}


@media screen and (max-width: 700px){

    .results{
        margin: 0;
        padding: 0;
    }

    .results__container h1, h4{
        margin: 20px;
        text-align: center;
        width: 100%;
    }

    .results__graphics{
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        width: 100%;
        height: 100%;
        align-items: baseline;
    }

    .secondary-graphics{
        flex-direction: row !important;
        justify-content: space-around;
        width: 100vw;
    }

    .principal-graphic{
        margin-left: -50px;
    }

    .competence-container{
        width: 100%;
        padding: 0 20px;
        margin-bottom: 20px;
        align-items: center;
    }

    .progress-bar{
        width: 100%;
    }

    .results--btn{
        margin: 40px;
        width: calc(100% - 40px);
    }

}