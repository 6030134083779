.login{
    width: 400px;
    max-width: 90%;
    height: 400px;
    background-color: white;
    padding: 30px;
    border-radius: 20px;
    -webkit-box-shadow: -1px 4px 12px 4px rgba(0,0,0,0.62);
    box-shadow: -1px 4px 12px 4px rgba(0,0,0,0.62);

}

.login__form{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

