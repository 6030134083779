#error-page{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-family: 'Raleway', sans-serif !important;

}

#error-page img{
    height: 500px;
    max-width: 300px;
}