.navbar{
    background-color: #D83D45 !important;
    font-family: 'Raleway', sans-serif !important;
    height: 85px;
}

.navbar-white{
    background-color: #ffffff !important;
    font-family: 'Raleway', sans-serif !important;
    /*font-weight: bold;*/
    color: #D83D45 !important;
    height: 85px;
    font-size: 20px;
    max-width: 100vw;
}

.navbar__container{
    background-color: #D83D45 !important;
    padding-left: 5% ;
    padding-right: 5% ;
    min-width: 100vw;
    color: #D83D45 !important;
    z-index: 2;
}

.navbar-container-white{
    background-color: #ffffff !important;
    min-width: 100vw;
    overflow-x: hidden;
}

.button-red{
    color: #D83D45 !important;
}

.navbar-brand a{
    font-size: 30px !important;
    display: flex;
}

.navbar--text{
    color: #D83D45 !important;
    alignment: center;
    margin: 0;
    display: flex;
    align-items: center;
    align-content: center;
}

.navbar a {
    color: white;
    text-decoration: none;
    font-size: 20px;
}

.navbar p{
    margin-bottom: 0;
    display: flex;
    align-items: center;
    align-content: center;
}

.navbar svg{
    alignment: center;
}

a:hover {
    color: white;
    text-decoration:none;
    cursor:pointer;
}

@media screen and (max-width: 900px){
    .brand-text{
        display: none !important;
    }
}