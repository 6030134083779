#home-trainer{
    min-height: calc(100vh - 100px);
}

.assessment-group{
    margin: 50px auto 50px auto;
}

.assessment-card{
    margin: 10px 0 10px 0
}