.form{
    font-family: Mali-SemiBold;
}

.space-btn{
    width: 100%;
    display: flex;
    justify-content: center;
}

.form--btn{
    margin-top: 20px;
    alignment: center;
    width: 300px;
}

.form__basic-data{
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.form__basic-data h2{
    font-size: 40px;
    text-align: center;

}

.basic-data__form-group{
    width: 100%;
}

.center-container{
    padding: 30px 60px 30px 60px;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.sliders-container{
    /*background-color: rgba(69, 121, 178, 0.4);*/
    width: 100%;
    border-radius: 30px;
    padding: 30px;
    height: 1300px;
}

.sliders-container > input{
    width: 80%;
}




@media screen and (max-width: 700px){
    .col-sm-9{
        padding: 0;
    }
    .form{
        width: 100vw;
        padding: 10px;
    }

    .form__basic-data h2{
        font-size: 30px;
    }
    .basic-data__form-group{
        width: 100%;
    }
    .sliders-container{
        width: 100%;
        height: auto;
    }
    .center-container {
        padding: 0 35px 0 35px;
    }
    .form__basic-data{
        padding: 20px 0;
    }
}