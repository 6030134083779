.graphic{
    /*font-family: Mali-SemiBold !important;*/
    margin-left: 0;
}

.apexcharts-datalabel{
    color: black !important;
    font-size: 12px;
}

.apexcharts-toolbar{
    display: none !important;
}

@media screen and (max-width: 700px){
    .apexcharts-datalabel{
        font-size: 10px;
    }
}