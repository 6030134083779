.DreyfusScale{
    height: 950px;
    margin-top: 30px ;
}

.DreyfusScale > .slider{
    height: 800px;
}

.DreyfusScale > .description{
    font-family: 'Raleway', sans-serif !important;
    text-align: justify;
    text-justify: inter-word;
}

.MuiSlider-markLabel{
    margin-left: 20px;
    white-space: normal !important;
    width: 40vw;
    font-family: 'Raleway', sans-serif !important;
    font-size: 1rem !important;
}

.MuiSlider-root.MuiSlider-vertical{
    margin-top: 20px;
    height: 80% !important;

}
.MuiSlider-vertical .MuiSlider-thumb{
    margin-left: -11px !important;
}

@media screen and (max-width: 900px){
    .MuiSlider-markLabel{
        width: 70vw;
    }
    .DreyfusScale{
        height: auto;
        margin-top: 30px ;
    }

    .DreyfusScale > .slider{
        height: 950px;
        margin-left: -20px;
        margin-top: 100px;
    }
}