.home{
    width: 100%;
    min-height: 100vh;
    padding: 0 !important;
    font-family: 'Raleway', sans-serif !important;
    background-color: #eb5e6b;
    background-image: url("../../assets/img/fondo.png");
    background-repeat: no-repeat !important;
    background-attachment: scroll !important;
    background-position: center center !important;
    background-size: cover !important;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    overflow-x: hidden;
    font-weight: 500;
    display: flex;
    justify-content: center;
    position: relative;
}

.home-container{
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;

    /*flex-direction: row;*/
}


.home__content{
    max-width: 650px;
    height: 80%;
    padding: 30px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
}


.home__content img{
    width: 60%;
    margin: 30px auto 30px auto;
}

.home__content--button{
    padding: 15px;
    width: 50%;
    margin: 0 auto 0 auto;
    font-size: 30px;
}

.home__content--description{
    padding: 0 10% 0 10%;
    text-align: center;
}

.image-container{
    display: flex;
    justify-content: flex-start;
    align-content: flex-end;
    align-items: flex-end;
    height: 100%;
}

.home-img{
    max-width: 585px;
    max-height: 550px;
    width: 400px;
    position: absolute;
    left: -28px;
    bottom: 0;
}

@media screen and (max-width: 900px){
    .home{
    }

    .home-img{
        display: none;
    }
    .image-container{
        height: 0;
    }
    .home__content--button{
        width: 250px;
    }
    .home__content{
        max-width: 100vw;
        width: 95%;
        box-sizing: border-box !important;
        padding: 0 20px 20px 20px;
    }
    .home__content--description{
        width: 95%;
    }
    .home-container{
        padding: 0;
    }
    .brand-text{
        display: none !important;
    }
}

