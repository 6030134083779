#trainer-login{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: #EC5E6C;
    font-family: Mali-SemiBold !important;
}